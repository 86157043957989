import Axios from 'axios';

const data = `[
  {
    "_id": "5809eadf477d0b0011e7ff60",
    "name": "LOOP Tilst",
    "address": "Bredskiftevej 20A",
    "longitude": 10.139096,
    "latitude": 56.177879,
    "gym_chain_id": {
        "_id": "5a53ece5ab4f950014e0b4f3",
        "name": "LOOP Fitness",
        "icon_url": "https://s3.eu-west-2.amazonaws.com/beefit-tracker/images/5a53ece1ab4f950014e0b4f2",
        "icon_id": "loop",
        "color": "#A9CB2C"
    },
    "city": "8210 Århus V",
    "gym_chain_cache": {
        "icon_url": "https://s3.eu-west-2.amazonaws.com/beefit-tracker/images/5a53ece1ab4f950014e0b4f2",
        "icon_sid": "loop"
    }
}
,
    {
        "_id": "59f1ae45592b160012142b4e",
        "name": "LOOP Tranbjerg",
        "address": "Børupvej 3,",
        "latitude": 56.094998,
        "longitude": 10.129001,
        "gym_chain_id": {
            "_id": "5a53ece5ab4f950014e0b4f3",
            "name": "LOOP Fitness",
            "icon_url": "https://s3.eu-west-2.amazonaws.com/beefit-tracker/images/5a53ece1ab4f950014e0b4f2",
            "icon_id": "loop",
            "color": "#A9CB2C"
        },
        "city": "8310 Tranbjerg",
        "gym_chain_cache": {
            "icon_url": "https://s3.eu-west-2.amazonaws.com/beefit-tracker/images/5a53ece1ab4f950014e0b4f2",
            "icon_sid": "loop"
        }
    },
    {
        "_id": "5ac3a2ac850dab0014bd2a26",
        "name": "LOOP Beder",
        "address": "Kirkebakken 4,",
        "latitude": 56.058694,
        "longitude": 10.205967,
        "gym_chain_id": {
            "_id": "5a53ece5ab4f950014e0b4f3",
            "name": "LOOP Fitness",
            "icon_url": "https://s3.eu-west-2.amazonaws.com/beefit-tracker/images/5a53ece1ab4f950014e0b4f2",
            "icon_id": "loop",
            "color": "#A9CB2C"
        },
        "city": "8330 Beder",
        "gym_chain_cache": {
            "icon_url": "https://s3.eu-west-2.amazonaws.com/beefit-tracker/images/5a53ece1ab4f950014e0b4f2",
            "icon_sid": "loop"
        }
    },
    {
      "_id": "5b9027e68637440014ffc9da",
      "name": "LOOP Greve",
      "address": "Håndværkerbyen 36",
      "city": "2670 Greve",
      "latitude": 55.589579,
      "longitude": 12.294733,
      "gym_chain_id": {
          "_id": "5a53ece5ab4f950014e0b4f3",
          "name": "LOOP Fitness",
          "icon_url": "https://s3.eu-west-2.amazonaws.com/beefit-tracker/images/5a53ece1ab4f950014e0b4f2",
          "icon_id": "loop",
          "color": "#A9CB2C"
      },
      "gym_chain_cache": {
          "icon_url": "https://s3.eu-west-2.amazonaws.com/beefit-tracker/images/5a53ece1ab4f950014e0b4f2",
          "icon_sid": "loop"
      }
  }
]`;
export const fetchGyms = async () => {
  if (process.env.NODE_ENV === 'development') {
    const gyms = JSON.parse(data);
    return gyms;
  } else {
    const gyms = await Axios.get('/api/gym/list');
    return gyms.data;
  }
};
