import * as React from 'react';
import { BeefitInNumbers } from '../components/index/beefitInNumbers';
import { GetMotivated } from '../components/index/getMotivated';
import { HowItWorksCustomer } from '../components/index/howItWorks';
import { BeefitMap } from '../components/index/map/beefitMap';
import '../layout/layout.scss';
import { ReadyToGetStarted } from '../components/index/getStarted';
import {
  SixTileInfoBox,
  IInfoBoxProps
} from '../components/shared/sixTileInfoBox';
import getTranslations from '../utils/getTranslations';
import motivation from '../../assets/atom/icon/benefit/motivation.svg';
import goals from '../../assets/atom/icon/benefit/goals.svg';
import friends from '../../assets/atom/icon/benefit/friends.svg';
import outdoor from '../../assets/atom/icon/benefit/outdoor.svg';
import workout from '../../assets/atom/icon/benefit/workout.svg';
import challenge from '../../assets/atom/icon/benefit/challenge.svg';

const IndexPage: React.FC = props => {
  const { makeFitnessMoreFun } = getTranslations().customer!;
  const m = makeFitnessMoreFun;

  const infoBoxes: IInfoBoxProps[] = [
    {
      title: m!.getMotivated!.title!,
      subtitle: m!.getMotivated!.subtitle!,
      imgUrl: motivation
    },
    {
      title: m!.workoutOutdoor!.title!,
      subtitle: m!.workoutOutdoor!.subtitle!,
      imgUrl: outdoor
    },
    {
      title: m!.reachYourGoals!.title!,
      subtitle: m!.reachYourGoals!.subtitle!,
      imgUrl: goals
    },
    {
      title: m!.planYourWorkouts!.title!,
      subtitle: m!.planYourWorkouts!.subtitle!,
      imgUrl: workout
    },
    {
      title: m!.connectWithFriends!.title!,
      subtitle: m!.connectWithFriends!.subtitle!,
      imgUrl: friends
    },
    {
      title: m!.doChallenges!.title!,
      subtitle: m!.doChallenges!.subtitle!,
      imgUrl: challenge
    }
  ];

  return (
    <div>
      <GetMotivated />
      <HowItWorksCustomer />
      <SixTileInfoBox
        title={m!.title!}
        subtitle={m!.subtitle!}
        infoBoxes={infoBoxes}
      />
      <BeefitInNumbers />
      <BeefitMap />
      <ReadyToGetStarted />
    </div>
  );
};

export default IndexPage;
