import * as React from 'react';
import getTranslations from '../../utils/getTranslations';
import './howItWorks.scss';
import { HowToBox } from '../shared/howToBox';
import { TitleAndSubtitle } from '../shared/titleAndSubtitle';
import watch from '../../../assets/illustrations/steps/user/wristbands.svg';
import scan from '../../../assets/illustrations/steps/user/scanner.svg';
import app from '../../../assets/illustrations/steps/user/app.svg';

export const HowItWorksCustomer: React.FC = () => {
  const { howItWorks } = getTranslations().customer!;

  return (
    <div className='how-it-works main-padding'>
      <div id='text'>
        <TitleAndSubtitle
          title={howItWorks!.title!}
          subtitle={howItWorks!.subtitle!}
        />
      </div>

      <div className='columns'>
        <div className='column'>
          <HowToBox number='1' imgUrl={watch} text={howItWorks!.step1!} />
        </div>
        <div className='column' id='how-to-box-2'>
          <HowToBox number='2' imgUrl={scan} text={howItWorks!.step2!} />
        </div>
        <div className='column'>
          <HowToBox number='3' imgUrl={app} text={howItWorks!.step3!} />
        </div>
      </div>
    </div>
  );
};
