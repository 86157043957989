import React from 'react';
import getTranslations from '../../utils/getTranslations';
import './getStarted.scss';
import { DownloadButton, DownloadType } from '../shared/downloadButton';
import { BrowserView, MobileView, isAndroid, isIOS } from 'react-device-detect';

export const ReadyToGetStarted: React.FC = props => {
  const { readyToGetStarted } = getTranslations().customer!;
  let innerWidth = 800;
  if (typeof window !== 'undefined') {
    innerWidth = window.innerWidth;
  }

  return (
    <div
      id='get-started-container'
      className={innerWidth > 768 ? 'main-padding' : ''}
    >
      <div id='get-started' className='has-text-centered'>
        <h2 id='title' className='column is-6 is-offset-3'>
          {readyToGetStarted!.title}
        </h2>
        <span id='subtitle' className='column is-5'>
          {readyToGetStarted!.subtitle}
        </span>

        <BrowserView>
          <div id='buttons'>
            <DownloadButton type={DownloadType.iOS} />
            <DownloadButton type={DownloadType.Android} />
          </div>
        </BrowserView>
        <MobileView>
          {isAndroid && <DownloadButton type={DownloadType.Android} />}
          {isIOS && <DownloadButton type={DownloadType.iOS} />}
        </MobileView>
      </div>
    </div>
  );
};
