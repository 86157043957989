import * as React from 'react';
import getTranslations from '../../utils/getTranslations';
import './getMotivated.scss';
import { DownloadButton, DownloadType } from '../shared/downloadButton';
import { isIOS, isAndroid, MobileView, BrowserView } from 'react-device-detect';
import { StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const query = graphql`
  query {
    desktop: file(relativePath: { eq: "getmotivated.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export const GetMotivated: React.FC = () => {
  const { getMotivated } = getTranslations().customer!;
  return (
    <StaticQuery
      query={query}
      render={data => {
        const imageData = data.desktop.childImageSharp.fluid;
        return (
          <BackgroundImage
            fluid={imageData}
            backgroundColor={'#2fc3fd'}
            style={{ backgroundPosition: '27%' }}
            className='get-motivated rounded-bottom-corner main-padding'
            critical={true}
          >
            <div className='column is-6'>
              <h1>{getMotivated!.title}</h1>
              <span>{getMotivated!.subtitle}</span>
              <BrowserView>
                <div id='buttons'>
                  <DownloadButton type={DownloadType.iOS} />
                  <DownloadButton type={DownloadType.Android} />
                </div>
              </BrowserView>
              <MobileView>
                {isAndroid && <DownloadButton type={DownloadType.Android} />}
                {isIOS && <DownloadButton type={DownloadType.iOS} />}
              </MobileView>
            </div>
          </BackgroundImage>
        );
      }}
    />
  );
};
