import React from 'react';

export const GymPin: React.FC<{
  color: string;
}> = props => {
  const size = 36;

  return (
    <svg
      width='24px'
      height={size}
      viewBox='0 0 24 36'
      style={{ transform: `translate(${-size / 2}px,${-size}px)` }}
    >
      <defs>
        <filter
          x='-383.3%'
          y='-255.6%'
          width='866.7%'
          height='611.1%'
          filterUnits='objectBoundingBox'
          id='filter-1'
        >
          <feOffset
            dx='0'
            dy='12'
            in='SourceAlpha'
            result='shadowOffsetOuter1'
          />
          <feGaussianBlur
            stdDeviation='18'
            in='shadowOffsetOuter1'
            result='shadowBlurOuter1'
          />
          <feColorMatrix
            values='0 0 0 0 0.294117647   0 0 0 0 0.768627451   0 0 0 0 0.984313725  0 0 0 0.326349432 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g
        id='atom/pin/normal'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='Group-6' filter='url(#filter-1)'>
          <path
            d='M13.2425527,34.1823892 C20.4141842,23.5774106 24,16.1832809 24,12 C24,5.372583 18.627417,0 12,0 C5.372583,0 0,5.372583 0,12 C0,16.1832805 3.5858151,23.5774093 10.7574453,34.1823862 L10.757441,34.1823891 C11.2215148,34.8686338 12.1540323,35.0487388 12.8402769,34.5846649 C12.9988024,34.4774619 13.1353497,34.3409146 13.2425527,34.1823892 Z'
            id='Oval'
            fill={props.color}
          />
          <circle id='Oval' fill='#FFFFFF' cx='12' cy='12' r='4' />
        </g>
      </g>
    </svg>
  );
};
