import React, { useEffect } from 'react';
import ReactMapGL, { Marker, Popup, ViewState } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { isMobile } from 'react-device-detect';
import './beefitMap.scss';
import { fetchGyms } from '../../../utils/fetchGyms';
import { GymPin } from './gymPin';

interface IGym {
  _id: string;
  name: string;
  address: string;
  gym_chain_id: {
    _id: string;
    name: string;
    icon_url: string;
    icon_id: string;
    color: string;
  };
  latitude: number;
  longitude: number;
  city: string;
}

interface IGymPopup {
  city: string;
  address: string;
  latitude: number;
  longitude: number;
  name: string;
}

export const BeefitMap: React.FC = props => {
  const [gyms, setGyms] = React.useState<IGym[]>([]);
  const [popupInfo, setPopupInfo] = React.useState<IGymPopup | null>(null);

  const initialViewPortMobile: ViewState = {
    latitude: 55.883302,
    longitude: 10.433326,
    zoom: 5.5
  };

  const initialViewPortDesktop: ViewState = {
    latitude: 55.483302,
    longitude: 9.433326,
    zoom: 6
  };

  const initialViewPort = isMobile
    ? initialViewPortMobile
    : initialViewPortDesktop;

  const [viewport, setViewport] = React.useState<ViewState>(initialViewPort);

  useEffect(() => {
    (async () => {
      const gymsData = await fetchGyms();
      setGyms(gymsData);
    })();
  }, []);

  const renderMarkers = gyms.map(
    ({
      longitude,
      latitude,
      _id,
      city,
      address,
      name,
      gym_chain_id: { color }
    }) => (
      <Marker key={_id} latitude={latitude} longitude={longitude}>
        <div
          onMouseOver={() => {
            setPopupInfo({ city, address, longitude, latitude, name });
          }}
          onMouseLeave={() => {
            setPopupInfo(null);
          }}
          onTouchStart={() =>
            setPopupInfo({ city, address, longitude, latitude, name })
          }
        >
          <GymPin color={color || '#A9CB2C'} />
        </div>
      </Marker>
    )
  );

  const renderPopup = () => {
    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor='top'
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeOnClick={false}
          onClose={() => setPopupInfo(null)}
        >
          <span id='popup-name' style={{ fontWeight: 700 }}>
            {popupInfo.name}
          </span>
          <div id='popup-address'>
            <span>{popupInfo.address}</span>
            <span>{popupInfo.city}</span>
          </div>
        </Popup>
      )
    );
  };

  return (
    <div id='beefit-map'>
      <ReactMapGL
        width='100%'
        height='100%'
        {...viewport}
        onViewportChange={viewPort => setViewport(viewPort)}
      >
        {renderMarkers}
        {renderPopup()}
      </ReactMapGL>
    </div>
  );
};
