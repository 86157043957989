import * as React from 'react';
import getTranslations from '../../utils/getTranslations';
import './beefitInNumbers.scss';
import pin from '../../../assets/atom/icon/24px/pin.svg';
import exercise from '../../../assets/atom/icon/24px/exercise.svg';
import plane from '../../../assets/atom/icon/24px/plane.svg';
import burger from '../../../assets/atom/icon/24px/burger.svg';

const NumbersBox: React.FC<{
  number: string;
  unit?: string;
  title: string;
  imgUrl: string;
}> = props => {
  return (
    <div className='has-text-centered column is-3 numbers-box'>
      <img src={props.imgUrl} style={{ marginBottom: '1rem' }} />
      <span className='beefit-number'>
        {props.number}
        <span className='unit'>{props.unit}</span>
      </span>
      <span className='numbers-box-title'>{props.title}</span>
    </div>
  );
};

export const BeefitInNumbers: React.FC = () => {
  const { beefitInNumbers } = getTranslations().customer!;
  return (
    <div id='beefit-in-numbers' className='main-padding'>
      <h2 id='title'>{beefitInNumbers!.title}</h2>
      <div className='columns'>
        <NumbersBox title={beefitInNumbers!.gyms!} imgUrl={pin} number='103' />
        <NumbersBox
          title={beefitInNumbers!.exercises!}
          imgUrl={exercise}
          number='15'
          unit='M'
        />
        <NumbersBox
          title={beefitInNumbers!.jumboJets!}
          imgUrl={plane}
          number='41'
          unit='k'
        />
        <NumbersBox
          title={beefitInNumbers!.cheeseburgers!}
          imgUrl={burger}
          number='910'
          unit='k'
        />
      </div>
    </div>
  );
};
